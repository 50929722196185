// extracted by mini-css-extract-plugin
export var slideNav = "developments-module--slideNav--1Lwp_";
export var slidePrev = "developments-module--slidePrev--3Z1cR";
export var slideNext = "developments-module--slideNext--31Xi5";
export var prevNextBottom = "developments-module--prevNextBottom--2MhQB";
export var accessStripe = "developments-module--accessStripe--2C8NG";
export var btmNav = "developments-module--btmNav--2zn0I";
export var btmPrev = "developments-module--btmPrev--y9sBM";
export var btmNext = "developments-module--btmNext--380M3";
export var devSideNav = "developments-module--devSideNav--1y0av";
export var sideToggle = "developments-module--sideToggle--jXdbM";
export var devSideOpened = "developments-module--devSideOpened--2DoPy";
export var devSideNavActive = "developments-module--devSideNavActive--20elx";
export var devContent = "developments-module--devContent--3dei3";
export var formWrap = "developments-module--formWrap--1r2jD";
export var facilitiesWrap = "developments-module--facilitiesWrap--1O_9q";
export var typeBarWrap = "developments-module--typeBarWrap--2eBHh";
export var typeToggle = "developments-module--typeToggle--1JIdr";
export var typeBar = "developments-module--typeBar--30gSZ";
export var activeType = "developments-module--activeType--2N_yB";
export var typeBarOpened = "developments-module--typeBarOpened--3V7Nj";
export var videoFrame = "developments-module--videoFrame--1e9Zr";
export var video = "developments-module--video--L57cL";
export var dlBtn = "developments-module--dlBtn--3Hcsv";
export var ectlgBtn = "developments-module--ectlgBtn--2I_De";
export var igIcon = "developments-module--igIcon--2WXGe";
export var igText = "developments-module--igText--2xoOe";
export var typeBarSlide = "developments-module--typeBarSlide--2RL8f";
export var typesOuterWrapper = "developments-module--typesOuterWrapper--FOTzH";
export var typesWrapper = "developments-module--typesWrapper--231C-";
export var btnItem = "developments-module--btnItem--1EL8m";
export var btnArrow = "developments-module--btnArrow--3sTLb";
export var btnPrev = "developments-module--btnPrev--1mjgQ";
export var btnNext = "developments-module--btnNext--1wRkk";
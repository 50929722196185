import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import classnames from "classnames"

import GridItem from "../components/gridItem"
import * as styles from "../styles/developments.module.css"

const DevSingle = ({
    title,
    logo,
    next,
    prev,
    mapSrc,
    pageLinks,
    activePage,
    goToForm,
    children,
}) => {
    const navRef = useRef()
    const [isOpened, setIsOpened] = useState(false)
    const toggleOpened = () => setIsOpened(!isOpened)
    const listClass = classnames({
        [styles.devSideOpened]: isOpened,
    })
    useEffect(() => {
        const checkIfClickOutside = e => {
            if (
                isOpened &&
                navRef.current &&
                !navRef.current.contains(e.target)
            ) {
                setIsOpened(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickOutside)
        }
    }, [isOpened])
    const colClass = classnames("flex my-4", {
        'justify-between lg:justify-center': !!goToForm,
        'justify-center lg:justify-center text-center': !goToForm
    })
    return (
        <>
            <div className="py-16 bg-white">
                <div className="container mx-auto">
                    <div className="flex flex-col lg:flex-row justify-center items-start px-4">
                        <div className="w-full lg:w-1/4">
                            <div className="flex justify-center items-start">
                                {logo && (
                                    <img src={logo} />
                                )}
                            </div>
                            <div className={colClass}>
                                <div ref={navRef}>
                                    <button
                                        className={`relative mx-auto py-4 lg:hidden ${styles.sideToggle}`}
                                        onClick={toggleOpened}
                                    >
                                        {activePage}
                                    </button>
                                    <nav
                                        className={`relative mx-auto ${styles.devSideNav} ${listClass}`}
                                    >
                                        <ul>
                                            {pageLinks.map((p, i) => {
                                                const liClass = classnames({
                                                    [styles.devSideNavActive]: p.label === activePage,
                                                    'block': p.isMobile,
                                                    'hidden lg:block': !p.isMobile
                                                })
                                                return (
                                                    <li
                                                        key={`p-${i}`}
                                                        className={liClass}
                                                    >
                                                        <Link to={p.link}>
                                                            {p.label}
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </nav>
                                </div>
                                {goToForm && (
                                    <div className="lg:hidden">{goToForm}</div>
                                )}
                            </div>
                        </div>
                        <div className={`w-full lg:w-3/4 ${styles.devContent}`}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            {prev && next ? (
                <div
                    className={`relative bg-white btm-nav ${styles.prevNextBottom}`}
                >
                    <div className="hidden w-full lg:grid grid-cols-2 gap-4">
                        <GridItem
                            height={140}
                            bgImage={prev.bgImage}
                            label={prev.label}
                            link={prev.link}
                        />
                        <GridItem
                            height={140}
                            bgImage={next.bgImage}
                            label={next.label}
                            link={next.link}
                        />
                    </div>
                    <Link
                        className={`${styles.btmNav} ${styles.btmPrev}`}
                        to={prev.link}
                    >
                        {prev.label}
                    </Link>
                    <Link
                        className={`${styles.btmNav} ${styles.btmNext}`}
                        to={next.link}
                    >
                        {next.label}
                    </Link>
                </div>
            ) : (
                ""
            )}
            <div className="access">
                <div
                    className={`font-serif text-center py-8 text-3xl ${styles.accessStripe}`}
                >
                    Access to <strong>{title}</strong>
                </div>
                {mapSrc && (
                    <iframe
                        title={`Cluster ${title}`}
                        src={mapSrc}
                        width="100%"
                        height={400}
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                    />
                )}
            </div>
        </>
    )
}

export default DevSingle
